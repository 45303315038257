<template>
  <v-container>
    <v-row>
      <v-col v-for="(project, index) in projects" :key="`project_${index}`" cols="12">
        <v-card class="pa-4">
          <v-row>
            <v-col cols="12" sm="4" class="d-flex align-items-center">
              <v-img :src="project.image ? project.image : '/img/bear_icon.png'" :aspect-ratio="1" contain></v-img>
            </v-col>
            <v-col>
              <v-card-title>
                {{ project.title }}
              </v-card-title>
              <v-card-text>
                {{ project.desc }}
              </v-card-text>
              <v-card-actions>
                <v-spacer> </v-spacer>
                <v-btn v-if="project.button.secondary" text :color="project.button.secondary.color" :to="{ name: project.button.secondary.route }">
                  {{ project.button.secondary.text }}
                </v-btn>
                <v-btn v-if="project.button.primary" :color="project.button.primary.color" :to="{ name: project.button.primary.route }">
                  {{ project.button.primary.text }}
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Homepage',
  data() {
    return {
      projects: [
        {
          title: 'Vehicle Manager',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          image: '',
          button: { primary: { text: 'manager', route: 'VehicleTrackerList', color: 'primary' } }
        },
        {
          title: 'Banana Stickers',
          desc: 'The great banana sticker race. The new standard in internet points.',
          image: 'img/banana.png',
          button: { primary: { text: 'stickers', route: 'BananaStickers', color: 'warning' } }
        },
        {
          title: 'Calculators',
          desc: 'Custom calculators; simple to complex.',
          image: '',
          button: { primary: { text: 'list', route: 'CalculatorList', color: 'primary' } }
        },
        {
          title: 'Budget',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          image: '',
          button: {}
        },
        {
          title: 'Recipes',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          image: '',
          button: {}
        },
        {
          title: 'To-do',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          image: '',
          button: {}
        }
      ]
    }
  }
}
</script>

<style></style>
